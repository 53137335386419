import * as React from 'react'
import { HotjarEvent, useTriggerHotjarSurvey } from '../lib/hooks/useTriggerHotjarSurvey'
import useDLPageViews from '../lib/hooks/tracking/useDLPageView'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { DynamicComponent } from '../components/DynamicComponent'
import { ProductBlok } from '../types'
import { ProductHeader } from '../components/Product/ProductHeader'
import { IndexSdkVariant } from '../components/Product/ProductSDKVariantHeader'
import { ProductNavigation } from '../components/Product/ProductNavigation'
import { Seo } from '../components/Seo'
import { ThemeName } from '../lib/constants'
import { sbEditable, SbEditableBlok } from '@storyblok/storyblok-editable'
import { useStoryblok } from '../lib/storyblok'
import { DevPlatformProductHeader } from '../components/Product/DeveloperPlatformVariantHeader'

const TemplateVariant = {
  default: ProductHeader,
  'sdk-variant': IndexSdkVariant,
  'developer-api': DevPlatformProductHeader
} as const

const SubNavigationVariant = {
  default: ProductHeader,
  'sdk-variant': IndexSdkVariant,
} as const

const ProductTemplate = ({ pageContext }: { pageContext: SbEditableBlok }) => {
  let { story, content } =
    typeof window !== 'undefined'
      ? useStoryblok<ProductBlok>(pageContext, window.location)
      : useStoryblok<ProductBlok>(pageContext)

  useDLPageViews(story)
  useTriggerHotjarSurvey(HotjarEvent.USER_PERSONA_SURVEY)

  const components = content.contentBlocks?.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  const templateVariant = content.templateVariant || 'default'
  const TemplateVariantComponent = TemplateVariant[templateVariant]

  return (
    <DefaultLayout
      subNavigation={<ProductNavigation blok={content} />}
      theme={content.colorTheme ? (`theme-${content.colorTheme}` as ThemeName) : undefined}
    >
      <Seo
        title={pageContext?.field_pageTitle_string || content?.productName}
        description={pageContext?.field_pageDescription_string || content?.headline}
        lang={pageContext?.lang}
        url={pageContext?.full_slug}
      />
      <div {...sbEditable(story)}>
        <TemplateVariantComponent blok={content} />
      </div>
      <div {...sbEditable(story)}>{components}</div>
    </DefaultLayout>
  )
}

export default ProductTemplate
