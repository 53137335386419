import { LANGUAGE } from '../../../../config/storyblok/languages'
import { TranslatedSlug } from '../../../types'

export type Translation = {
  [key in keyof TranslationKey]: string
}

export enum TranslationKey {
  general_next = 'general_next',
  general_prev = 'general_prev',
  general_readMore = 'general_readMore',
  general_viewMore = 'general_viewMore',
  general_viewLess = 'general_viewLess',
  general_operationSystem = 'general_operationSystem',
  codeSamples_downloadCodeSamples = 'codeSamples_downloadCodeSamples',
  general_country = 'general_country',
  general_industry = 'general_industry',
  general_companySize = 'general_companySize',
  general_usedProducts = 'general_usedProducts',
  customerStory_briefPortrait = 'customerStory_briefPortrait',
  general_ProductsForThisCase = 'general_ProductsForThisCase',
  description = 'description',
}

export interface I18nContextValue {
  currentLanguage: string
  translations: Translation | {}
  allLanguages: LANGUAGE[]
  translatedSlugsForPage: Record<LANGUAGE, string> | null
  defaultSlug: string
  getTranslatedUrlFromLink: (SbLink) => string
  getTranslatedUrlFromBlok: (fullSlug: string, translatedSlugs: TranslatedSlug[]) => string
}

interface SbLinkItem {
  slug: string
  uuid: string
  alternates: TranslatedLink[]
}

export interface LinkList {
  nodes: SbLinkItem[]
}

export type TranslatedLinkList = Record<string, Link>

export interface Link {
  defaultSlug: string
  translatedSlugs: TranslatedLink[]
}

export type TranslatedLink = TranslatedSlug
