import { SbEditableBlok } from '@storyblok/storyblok-editable'

const getFieldsFromStory = <T extends Object>(story: SbEditableBlok): T => {
  const fields: T = Object.create({})

  Object.keys(story).forEach(key => {
    const keySplitted = key.split('_')
    if (keySplitted.includes('field') && keySplitted.length > 0) {
      // fields are passed like: field_variableName_type
      const newKey = keySplitted[1]
      fields[newKey] = story[key]
    }
  })

  return fields
}

export default getFieldsFromStory
