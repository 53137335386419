import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const ButtonWrapper = styled.div`
  max-width: 40vw;
  overflow: hidden;
`

export const DropdownWrapper = styled.div<{ isOpen?: boolean }>`
  background: ${p => p.theme.colors.white[1]};
  border-radius: 0.9rem;
  box-shadow: ${p => p.theme.shadows.menuOpen};
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  left: -0.4rem;
  opacity: ${p => (p.isOpen ? 1 : 0)};
  padding: 0.5rem;
  position: absolute;
  top: 100%;
  min-width: 10rem;
  transition: opacity 0.15s, visibility 0.15s;
  visibility: ${p => (p.isOpen ? 'visible' : 'hidden')};
`
