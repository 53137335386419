import * as React from 'react'
import { isExternalLink, isServer } from '../../lib/utils'
import { ProductBlok } from '../../types'
import { Subnavigation, SubnavigationGroup, SubnavigationLink } from '../UI/Subnavigation'
import { Dropdown } from '../UI/Subnavigation/Dropdown'
import CustomLink from '../CustomLink'
import { useI18n } from '../../lib/context/i18n'
import { Divider } from '../OnboardingWizzard/styled'

export interface ProductNavigationProps {
  blok: ProductBlok
}

export const ProductNavigation = ({ blok }: ProductNavigationProps) => {
  const [isActive, setIsActive] = React.useState(false)
  const currentUrl = !isServer() ? window?.location?.pathname : ''


  React.useEffect(() => {
    if (currentUrl === '/products/conversion/pdf-tools-sdk/' || currentUrl === '/products/conversion/pdf-tools-sdk/?product_code=_PDFSDK') {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [currentUrl])

  const { getTranslatedUrlFromLink } = useI18n()
  if (!blok?.subnavigation || !blok?.subnavigation[0]) return null

  const subNavigation = blok?.subnavigation[0]

  const dropdownLinks = subNavigation.pageLinks?.map(link => ({
    label: link.text,
    to: getTranslatedUrlFromLink(link.link),
  }))


  return (
    <Subnavigation>
      {subNavigation?.overview && subNavigation?.overview.length ? <SubnavigationGroup>
        {subNavigation.overview?.map(link => {
          const path = getTranslatedUrlFromLink(link.link)
          return (
            <SubnavigationLink
              icon={isExternalLink(path) ? 'link-external' : link?.icon}
              as={CustomLink}
              to={link.product_code ? `${path}?product_code=${link.product_code}` : path}
              target={isExternalLink(path) ? '_blank' : undefined}
              key={link.id}
              isActive={isActive}
            >
              {link.text}
            </SubnavigationLink>
          )
        })}
      </SubnavigationGroup> : <></>
      }
      {subNavigation.overview && subNavigation.overview.length ?
        <SubnavigationGroup>
          <Divider />
        </SubnavigationGroup>
        : <></>
      }
      {!blok?.isDropdown ? <SubnavigationGroup isDesktop>
        {subNavigation.pageLinks?.map(link => {
          const path = getTranslatedUrlFromLink(link.link)
          return (
            <SubnavigationLink
              icon={isExternalLink(path) ? 'link-external' : link?.icon}
              as={CustomLink}
              to={path}
              target={isExternalLink(path) ? '_blank' : undefined}
              key={link.id}
            >
              {link.text}
            </SubnavigationLink>
          )
        })}
      </SubnavigationGroup>
        :
        <>
          {dropdownLinks && (
            <SubnavigationGroup>
              <Dropdown items={dropdownLinks} />
            </SubnavigationGroup>
          )}
        </>}
      {dropdownLinks && !blok?.isDropdown && (
        <SubnavigationGroup isMobile>
          <Dropdown items={dropdownLinks} />
        </SubnavigationGroup>
      )}
      <SubnavigationGroup>
        {subNavigation.callToAction?.map(link => {
          const path = getTranslatedUrlFromLink(link.link)

          return (
            <SubnavigationLink
              icon="spark"
              as={CustomLink}
              to={link.product_code ? `${path}?product_code=${link.product_code}` : path}
              target={isExternalLink(path) ? '_blank' : undefined}
              key={link.id}
              useTheme
            >
              {link.text}
            </SubnavigationLink>
          )
        })}
      </SubnavigationGroup>
    </Subnavigation>
  )
}
