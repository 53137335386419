import styled from 'styled-components'
import { containerDefault } from '../../../lib/styles/Container'
import { typoHeading5, typoHeadingXl, typoParagraph, typoParagraphIntro } from '../../../lib/styles/Typography'

export const Wrapper = styled.header`
  ${containerDefault};
  margin-block: 4rem;
  margin-inline: auto;
`

export const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  margin-bottom: 6rem;

  @media screen and (max-width: 1300px) {
    margin-bottom: 5rem;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 4rem;
  }
`

export const HeaderRowLeft = styled.div`
  flex: 1 1 auto;
  max-width: 33rem;
  width: 50%;

  @media screen and (max-width: 900px) {
    max-width: none;
    width: 100%;
  }
`

export const HeaderRowRight = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media screen and (max-width: 900px) {
    order: -1;
    width: 100%;
  }
`

export const ProductIcon = styled.img`
width: 300px;
height: 330px;
`

export const ProductName = styled.div`
  ${typoParagraphIntro};
  color: #0D8FF2;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

export const ProductHeadline = styled.h1`
  ${typoHeadingXl}
`

export const DescriptionTitle = styled.div`
  ${typoHeading5};
  margin-bottom: 1rem;
`

export const DescriptionText = styled.div`
  ${typoParagraph}
`

export const ViewMoreButtonWrapper = styled.div`
  margin-top: 1.5rem;
`

export const NavButtons = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 3rem;
`

export const InfoRow = styled.div`
  display: flex;
  gap: 4rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 2rem;
  }
`

export const InfoRowLeft = styled.div`
  flex: 1 1 auto;
  max-width: 33rem;
`

export const InfoRowTrim = styled.hr`
  background-color: ${p => p.theme.colors.black[6]};
  border: 0;
  flex: 0 0 auto;
  margin: 0;
  width: 1px;

  @media screen and (max-width: 900px) {
    height: 1px;
    width: 100%;
  }
`

export const InfoRowRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  gap: 1rem;
`
