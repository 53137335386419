import * as React from 'react'
import { useI18n, useTranslation, useTranslationString } from '../../../lib/context/i18n'
import { richtextToText } from '../../../lib/utils/richtextToText'
import { ProductBlok } from '../../../types'
import { Button } from '../../UI/Button'
import { IconList } from '../../UI/IconList'
import { InfoList } from '../../UI/InfoList'
import CustomLink from '../../CustomLink'
import { IconListItem } from '../../UI/IconList/types'
import { TranslationKey } from '../../../lib/context/i18n/interfaces'
import {
  DescriptionText,
  DescriptionTitle,
  HeaderRow,
  HeaderRowLeft,
  HeaderRowRight,
  InfoRow,
  InfoRowLeft,
  InfoRowRight,
  InfoRowTrim,
  NavButtons,
  ProductHeadline,
  ProductIcon,
  ProductName,
  ViewMoreButtonWrapper,
  Wrapper,
} from './styled'
import EmailSubmit from '../../../modules/EmailSubmit'
import { useButtonTracking } from '../../../modules/Button/useButtonTracking'

export interface ProductHeaderProps {
  blok: ProductBlok
}

export const ProductHeader = ({ blok }: ProductHeaderProps) => {
  const [descriptionOpen, setDescriptionOpen] = React.useState(false)
  const productVariants = blok.productVariant
  const operationSystems = blok.operationsSystems
  const { getTranslatedUrlFromLink } = useI18n()
  const t = useTranslation()

  const toggleDescription = React.useCallback(() => {
    setDescriptionOpen(val => !val)
  }, [])

  const descriptionString = blok.description && richtextToText(blok.description)

  const showMore = blok.shortDescription.length > 320

  const description = showMore
    ? descriptionOpen
      ? descriptionString
      : `${descriptionString?.slice(0, 320)}...`
    : descriptionString

  const osIcons = operationSystems?.map(os => ({
    icon: os.content.icon,
    name: os.content.name,
  }))

  const variantIcons = productVariants?.map(variant => {
    return (
      variant.content.icon !== 'icon-watched-folder' && {
        icon: variant.content.icon,
        name: variant.content.name,
      }
    )
  })

  const { onClickHandler: onClickHandler0 } = useButtonTracking(blok.buttons[0]);
  const { onClickHandler: onClickHandler1 } = useButtonTracking(blok.buttons[1]);

  return (
    <Wrapper>
      <HeaderRow style={{
        display: 'flex',
        flexDirection: blok?.noImage ? 'column' : 'row',
        gap: '1rem',
        width: '100%',
      }}>
        <HeaderRowLeft>
          <ProductName style={{
            textAlign: blok.noImage ? 'center' : 'start',
          }}>{blok.productName}</ProductName>
          <ProductHeadline style={{
            textAlign: blok.noImage ? 'center' : 'start',
          }}>{blok.headline}</ProductHeadline>
          <DescriptionText style={{
            textAlign: blok.noImage ? 'center' : 'start',
          }}>{description}</DescriptionText>
          {blok.emailSubmit ? <EmailSubmit productCode={blok.productCode} placeholder={blok.inputPlaceholder} ctaLabel={blok.ctaLabel} /> :
            blok.buttons.length > 0 ? (
              <NavButtons style={{
                display: 'flex',
                justifyContent: blok.noImage ? 'center' : 'lex-start',
              }}>
                <Button
                  as={CustomLink}
                  $variant="primary"
                  to={
                    blok.buttons[0]?.product_code
                      ? `${getTranslatedUrlFromLink(blok.buttons[0]?.link)}?product_code=${blok.buttons[0]?.product_code}`
                      : getTranslatedUrlFromLink(blok.buttons[0]?.link)
                  }
                  onClick={onClickHandler0}
                >
                  {blok.buttons[0]?.button_name}
                </Button>
                {blok.buttons[1] && (
                  <Button
                    as={CustomLink}
                    $variant="secondary"
                    to={getTranslatedUrlFromLink(blok.buttons[1]?.link)}
                    iconRight={blok.buttons[1]?.link_target && 'link-external'}
                    onClick={onClickHandler1}
                  >
                    {blok.buttons[1]?.button_name}
                  </Button>
                )}
              </NavButtons>
            ) : <></>
          }

        </HeaderRowLeft>
        <HeaderRowRight>
          {blok?.noImage ? <></> : <ProductIcon src={blok.image.filename} alt={blok.image.alt} />}
        </HeaderRowRight>
      </HeaderRow>
      <InfoRow style={{
        display: 'flex',
        justifyContent: blok?.noImage ? 'center' : 'flex-start',
        gap: '1rem',
        width: '100%',
      }}>
        <IconList icons={[...osIcons, ...variantIcons] as IconListItem[]} />
        <InfoRowTrim />
        <span>Seamlessly runs on-premise and in the cloud in {'<60'} seconds</span>
      </InfoRow>
    </Wrapper>
  )
}
