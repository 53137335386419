import { Richtext } from "storyblok-js-client";

type Element = {
  type: string
  text?: string
  content?: Element[]
}

export const mapContentToText = (items: Array<Element>) => {
  let output = ``  
  items && items.forEach((item) => {
    if (item.text) {
      output += item.text
    }

    if (item.content) {
      output += mapContentToText(item.content)
    }
  })

  return output
}

export const richtextToText = (richtext: Richtext) => {
  return mapContentToText(richtext.content as Element[])
}