import * as React from 'react'
import { SubnavigationLink } from '../index'
import { isExternalLink, isServer } from '../../../../lib/utils'
import { ButtonWrapper, DropdownWrapper, Wrapper } from './styled'
import { DropdownProps } from './types'

export const Dropdown = ({ items, currentPath }: DropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const currentUrl = currentPath || !isServer() ? window.location.pathname : ''
  const currentUrlWithoutEndingSlash = currentUrl.replace(/\/$/, '')

  const currentItem = React.useMemo(() => {
    return items.find(item => {
      return item.to === currentUrl || item.to === currentUrlWithoutEndingSlash
    })
  }, [items, currentUrl])

  React.useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsOpen(false)
        }
      }
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [isOpen])

  return (
    <Wrapper ref={containerRef}>
      <ButtonWrapper>
        <SubnavigationLink
          isActive={!!currentItem}
          as="button"
          icon="arrow-down-small"
          onClick={() => setIsOpen(!isOpen)}
        >
          {currentItem?.label || 'Use cases'}
        </SubnavigationLink>
      </ButtonWrapper>
      <DropdownWrapper ref={wrapperRef} isOpen={isOpen}>
        {items.map(item => (
          <SubnavigationLink
            as="a"
            href={item.to}
            key={item.to}
            icon={isExternalLink(item?.to) ? 'link-external' : undefined}
            isActive={item.to === currentUrl || item.to === currentUrlWithoutEndingSlash}
            isFullwidth
          >
            {item.label}
          </SubnavigationLink>
        ))}
      </DropdownWrapper>
    </Wrapper>
  )
}
