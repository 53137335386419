import { func } from 'prop-types';
import { useEffect } from 'react';

declare global {
    interface Window {
        hj(key: 'event', event: string): void;
    }
}


export enum HotjarEvent {
    USER_PERSONA_SURVEY = 'user_persona_survey'
}


function isValidDate(date) {
    return date instanceof Date;
}

function isDateOlderThan(date, days) {
    const now = new Date();
    return now.getDate() - date.getDate() >= days
}

export function useTriggerHotjarSurvey(event: HotjarEvent) {
    useEffect(() => {
        const timeId = setTimeout(() => {
            const localValue = window.localStorage.getItem(event);
            const date = new Date(localValue)

            if (!localValue || !isValidDate(date) || isDateOlderThan(date, 7)) {
                window.hj('event', event);
                window.localStorage.setItem(event, Date.now().toString())
            }
        }, 3000)

        return () => {
            clearTimeout(timeId)
        }
    }, [])
}