import axios from 'axios'
import React, { useEffect } from 'react'
import { useI18n, useTranslation } from '../../../lib/context/i18n'
import { richtextToText } from '../../../lib/utils/richtextToText'
import { ProductBlok } from '../../../types'
import { Button } from '../../UI/Button'
import {
  HeaderRow,
  HeaderRowLeft,
  HeaderRowRight,
  NavButtons,
  ProductHeadline,
  ProductIcon,
  ProductName,
  Wrapper,
} from './styled'
import styled from 'styled-components'
import { StyledDisturber } from '../../../modules/Disturber/styled'
import emailValidator from 'email-validator';




export const StyledErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red[3]};
  font-weight: 600;
`


export interface ProductHeaderProps {
  blok: ProductBlok
}

const StyledInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px 8px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  margin-top: 0px;
  border: 1.5px solid var(--black-black-6-s-10-l-94, #f0f0ef);
  background: var(--white-white-1, #fff);
`

const StyledTextInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background: #fff;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
   -webkit-box-shadow: 0 0 0 30px white inset !important;
  &::placeholder {
    color: #0D0D0D;
    opacity: 0.4;
  }
`

const ProductHeadlineCustom = styled(ProductHeadline)`
  font-size: 3rem;
`

export const DevPlatformProductHeader = ({ blok }: ProductHeaderProps) => {
  const { getTranslatedUrlFromLink } = useI18n()
  const t = useTranslation()


  const descriptionString = blok.description && richtextToText(blok.description)

  const showMore = blok.shortDescription.length > 320

  const [email, setEmail] = React.useState('')
  const { registerUser, hasSucceeded, error, loading } = usePDFToolsAPIregistration()

  // TODO: create an effect and state for is email a valid email
  const [isEmailValid, setIsEmailValid] = React.useState(false)
  useEffect(() => {
    setIsEmailValid(emailValidator.validate(email))

  },
    [email]
  )

  return (
    <Wrapper>
      <HeaderRow>
        <HeaderRowLeft>
          <ProductName>
            {blok.productName}
            <span style={{
              marginLeft: '10px',
              backgroundColor: '#0D8FF2',
              color: '#fff',
              padding: '2px 5px',
              borderRadius: '0.5rem',
              fontSize: '0.8rem',
            }} >Free Beta</span>
          </ProductName>
          <ProductHeadlineCustom>{blok.headline}</ProductHeadlineCustom>
          {blok.buttons && (
            <NavButtons>
              {!hasSucceeded && <StyledInput>
                <StyledTextInput
                  name="email"
                  type="email"
                  // TODO: map in storyblok
                  placeholder={'Your email address'}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button $variant="quaternary" disabled={!isEmailValid || loading} $inverted
                  onClick={() => registerUser(email)}
                >
                  Get API Key
                </Button>
              </StyledInput>}
              {/* error */}
              {error &&
                <StyledErrorMessage> {'Something went wrong. Please try again later.'}</StyledErrorMessage>}

              {hasSucceeded &&
                <StyledDisturber className={blok.theme && `theme-${blok.theme}`} size='s'>
                  < span > {'Thank you for registering!'}</span>
                  <br />
                  < span > {'We will send you your API key shortly.'}</span>
                </StyledDisturber>}
              {!hasSucceeded && <>
                <span>{'One click to join our free beta. No credit card needed!'}</span>
                <br />
                <span style={{
                  fontSize: '0.8rem',
                  color: '#6E6E6E',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}>
                  By signing up you are agreeing to our <a href={'https://pdftools-public-downloads-production.s3.eu-west-1.amazonaws.com/devplat/legal/Pdftools+API+-+Terms+and+Conditions.pdf'} target={'_blank'}>
                    Terms of use
                  </a>
                </span>
              </>}
            </NavButtons>
          )}
        </HeaderRowLeft>
        <HeaderRowRight>
          <ProductIcon src={blok.image.filename} alt={blok.image.alt} />
        </HeaderRowRight>
      </HeaderRow>

    </Wrapper >
  )
}


const usePDFToolsAPIregistration = () => {
  const [error, setError] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [loading, setloading] = React.useState(false)
  // has succeeded state
  const [hasSucceeded, setHasSucceeded] = React.useState(false)


  const registerUser = async (email: string) => {
    if (loading) return;
    setError(false)
    try {
      setloading(true)
      setHasSucceeded(false)

      // TODO: Move this endpoint to env variable
      const response = await axios.post(`${'https://api-registration.pdf-tools.com'}/api/v1/registration`, { email });
      setHasSucceeded(true)
      setData(response.data)
    } catch (error) {
      setError(true)
    }
    finally {
      setloading(false)
    }
  }

  return {
    registerUser,
    hasSucceeded,
    error,
    loading,
  }
}
